import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";

import { Dropdown, Layout, Menu } from "antd";

import { BrowserScrollService } from "@utils/BrowserScrollService";
import { StorageKeys } from "@utils/LocalStorage";
import { Logger } from "@utils/Logger";
import { getHmacKey, getLoggedInUserId, getUserEmail, getUserRole } from "@utils/UserUtils";

import { ProfileModel } from "@common/domain/models/Profile";
import { LMS_COOKIES_REMOVE } from "@common/network/constants";
import {
  isAuthenticatedSelector,
  isBetaUser,
  isWebView
} from "@common/redux/auth/selectors";
import { getProfileMap } from "@common/redux/profile/selectors";

import {
  LogoutOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  UserOutlined
} from "@assets/antdIcons";
import { WF_Logo_Color } from "@assets/index";
import { translate } from "@translations/Translater";

import { WFImg, WFProfilePic } from "@components/index";
import ContentWrapper from "@components/LayoutContainers/ContentWrapper";
import WFMenuItem from "@components/Menu";
import { WFModal } from "@components/Modal/WFModal";
import Text, { FontFamilyVariant, TextType } from "@components/Text";
import Navigator from "@navigator/index";
import { NavigationUrl, URLs } from "@navigator/NavigationUrl";

import { userRoles } from "@constants/config";

import Img from '@components/atoms/Img';

import Divider from "@components/Divider";
import Config from '@config/index';
import { LayoutUtil } from "@layout/LayoutUtil";
import { layoutMessages } from "@layout/messages";
import DashBoardMenu from "./components/DashBoardMenu";
import TabView from "./components/TabView";

import { BrowseRoleMenuIcon, FavoriteMenuIcon, MatchedRolesMenuIcon, ShareReportMenuIcon } from "@assets/images";
import "./styles.less";

const { Header, Sider, Content } = Layout;

interface IProps {
  component: React.ComponentType;
  location: any;
  isAuthenticated: boolean;
  t: any;
  isWebView?: boolean;
  isBetaUser?: boolean;
  profileMap?: {};
}

interface IState {
  current: string;
  selectedDesktopMenuKey: string;
  selectedMobileTabKey: string;
  showModal: boolean;
  closeNudge: string;
}

const setParentRef = (pRef: any) => {
  BrowserScrollService.init(pRef);
};

export interface IAppMenuItem {
  url: string;
  name: any;
  key: string;
  icon?: React.ReactNode;
  iconImage?: React.ReactNode;
  locked?: boolean;
}




const getAppMenuItemsObject = (profileInfo: ProfileModel) => {

  const APP_MENU_ITEMS = Object.freeze({
    MATCHED_ROLES: {
      url: NavigationUrl.generate(URLs.matchedRoles),
      name: layoutMessages.matchedRoleMenu,
      key: 'matchedRoles',
      iconImage: <WFImg src={MatchedRolesMenuIcon} alt="Matched-Roles"/>,
      enabled: true,
    },

    BROWSE_ROLES: {
      url: NavigationUrl.generate(URLs.browseRoles),
      name: layoutMessages.browsedRoleMenu,
      key: 'browseRoles',
      iconImage: <WFImg src={BrowseRoleMenuIcon} alt="Browse-Roles"/>,
      enabled: true,
    },

    FAVOURITES: {
      url: NavigationUrl.generate(URLs.favourites),
      name: layoutMessages.favouritesMenu,
      key: 'favoutites',
      iconImage: <WFImg src={FavoriteMenuIcon} alt="Favorite"/>,
      enabled: true,
    },
    SHARE_REPORT: {
      url: NavigationUrl.generate(URLs.shareReport),
      name: layoutMessages.shareReportMenu,
      key: 'shareReport',
      iconImage: <WFImg src={ShareReportMenuIcon} alt="Share-Report"/>,
      enabled: true,
      locked: true
    },

  });

  return APP_MENU_ITEMS;
}

const createSidebarItems = (persona, profileInfo, APP_MENU_ITEMS) => {
  const betaFeatures: string[] = profileInfo?.getBetaFeatures();
  const items = [];
  for (let i in APP_MENU_ITEMS) {
    if (APP_MENU_ITEMS[i].enabled && persona.includes(APP_MENU_ITEMS[i])) {
      items.push(APP_MENU_ITEMS[i]);
    }
  }
  return items;
}

export const getMenuItems = (role: userRoles | string, profileInfo: ProfileModel): IAppMenuItem[] => {

  const APP_MENU_ITEMS = getAppMenuItemsObject(profileInfo);

  const student = [APP_MENU_ITEMS.MATCHED_ROLES, APP_MENU_ITEMS.BROWSE_ROLES, APP_MENU_ITEMS.FAVOURITES, APP_MENU_ITEMS.SHARE_REPORT];
  const faculty = [APP_MENU_ITEMS.BROWSE_ROLES, APP_MENU_ITEMS.FAVOURITES];
  const regionalManager = [APP_MENU_ITEMS.BROWSE_ROLES, APP_MENU_ITEMS.FAVOURITES];
  const expert = [APP_MENU_ITEMS.BROWSE_ROLES, APP_MENU_ITEMS.FAVOURITES];

  switch (role) {
    case userRoles.student: return createSidebarItems(student, profileInfo, APP_MENU_ITEMS);
    case userRoles.faculty: return createSidebarItems(faculty, profileInfo, APP_MENU_ITEMS);
    case userRoles.regionalManager: return createSidebarItems(regionalManager, profileInfo, APP_MENU_ITEMS);
    case userRoles.expert: return createSidebarItems(expert, profileInfo, APP_MENU_ITEMS);
    default: return [APP_MENU_ITEMS.MATCHED_ROLES];
  }
};


class Dashboard extends React.Component<IProps, IState> {
  scrollRef: any;

  constructor(props) {
    super(props);
    this.scrollRef = React.createRef();
  }

  private getSideMenuItems = (): IAppMenuItem[] => {
    const { t, profileMap } = this.props;
    const profileInfo: ProfileModel = profileMap[profileMap[getLoggedInUserId()]];
    let menuItems: IAppMenuItem[] = getMenuItems(getUserRole(), profileInfo);
    return menuItems.map((menuItem: IAppMenuItem) => {
      return {
        ...menuItem,
        name: translate(t, menuItem.name)
      }
    })
  };

  private getInitialState = (): IState => {
    const pathName = this.props.location.pathname;
    const menuKey = LayoutUtil.getMenuByPathName(pathName, this.getSideMenuItems());

    return {
      selectedDesktopMenuKey: menuKey,
      selectedMobileTabKey: menuKey,
      current: "",
      showModal: false,
      closeNudge: localStorage.getItem(StorageKeys.SHOW_APP_NUDGE),
    };

  }

  state = this.getInitialState();

  public async componentDidMount() {
    const userId = getLoggedInUserId();
    const reactNode = this.scrollRef.current;
    this.setDefaultSelectedMenu();
    setParentRef(reactNode);
  }

  public onClosingNudge = (payload) => {
    this.setState({ closeNudge: payload });
  }

  render() {
    const { isWebView } = this.props;

    const nudgeClass = (!(localStorage.getItem(StorageKeys.SHOW_APP_NUDGE) === "false" || this.state.closeNudge === "false") && !isWebView) ? "app__layout__outer_content_nudges" : "";

    if (isWebView)
      return (
        <Content className="app__layout__inner_content_webview">
          {this.renderContent()}
        </Content>
      );

    return (
      <ContentWrapper className="layout_wrapper">
        <Layout className="app__layout__dash">
          <Header className="layout_header">{this.renderHeader()}</Header>
          {/* {!isWebView && <Nudges onClick={this.onClosingNudge} />} */}
          {/* <Layout className={`app__layout__outer_content ${nudgeClass}`}> */}
          <Layout className={`app__layout__outer_content`}>
            <Content className="app__layout__inner_content"
              id="app__layout__inner_content">
              {this.renderContent()}
              {this.state.showModal && this.renderTicketModal()}
            </Content>
            <div className="app_layout_sm_tab">
              {this.renderTabs()}
            </div>
          </Layout>
        </Layout>
      </ContentWrapper>
    );
  }

  private renderContent = () => {
    const Component = this.props.component;
    return <Component {...this.props} />;
  };

  private setSelectedMenu = (selectedKey: string) => {
    this.setState({
      selectedDesktopMenuKey: selectedKey,
      selectedMobileTabKey: selectedKey,
    });
  };

  private setDefaultSelectedMenu = () => {
    const pathName = this.props.location.pathname;
    const menuKey = LayoutUtil.getMenuByPathName(pathName, this.getSideMenuItems());

    this.setState({
      selectedDesktopMenuKey: menuKey,
      selectedMobileTabKey: menuKey,
    });
  };


  private renderMenu = () => {
    const loggedInUserrole = localStorage.getItem(StorageKeys.USER_ROLE);
    const menuItems: IAppMenuItem[] = this.getSideMenuItems();
    return (
      <DashBoardMenu
        onMenuChange={this.setSelectedMenu}
        selectedKey={this.state.selectedDesktopMenuKey}
        menuItems={menuItems}
      />
    );
  };

  private renderTicketModal = () => {
    const { profileMap } = this.props;
    const profileInfo: ProfileModel = profileMap[profileMap[getLoggedInUserId()]];
    const userEmail = getUserEmail();
    const hmacKey = getHmacKey(Config.CRISP_WEB_CONFIG.hmac_secret, userEmail);
    const profileName = profileInfo?.getName() || "";
    return (
      <WFModal
        centered
        showModal={this.state.showModal}
        onCancel={() => { this.setState({ showModal: false }) }}
      >
        <iframe
          title="Ticket Center"
          src={`https://plugins.crisp.chat/urn:crisp.im:ticket-center:0/tickets/577abe07-4cb8-4a99-8042-d211534a967b?email=${userEmail}&hmac=${hmacKey}&nickname=${profileName}&locale=${profileInfo?.getPreferredLanguage()}`}
          referrerPolicy="origin"
          sandbox="allow-forms allow-popups allow-modals allow-scripts allow-same-origin"
          width="100%"
          height="600px"

          frameBorder="0">
        </iframe>
      </WFModal>
    );
  }

  private renderTabs = () => {
    const { isBetaUser } = this.props;
    const loggedInUserrole = localStorage.getItem(StorageKeys.USER_ROLE);
    const tabtems: IAppMenuItem[] = this.getSideMenuItems();
    // code to exclude any particular tab item

    return (
      <TabView
        activeKey={this.state.selectedMobileTabKey}
        onChange={this.setSelectedMenu}
        tabItems={tabtems}
      />
    );
  };

  private renderLogo = () => {
    return (
      <div onClick={null} className="layout_logo">
        <Img alt="wadhwani logo white color" src={WF_Logo_Color} className="wadhwani-logo" />
        {/* ?\ remove moodle cookies */}
        <Img src={LMS_COOKIES_REMOVE} alt="lms-cookies-remove" className="lms_cookies" />
      </div>
    );
  };

  private renderHeader = () => {
    return (
      <>
        {this.renderLogo()}
        <Divider type="vertical" className="menu_divider"/>
        {this.renderHeaderMenu()}
      </>
    );
  };

  private renderHeaderMenu = () => {
    const { t, profileMap } = this.props;
    const USER_OPTIONS: string[] = [
      translate(t, layoutMessages.myProfileTitle),
      translate(t, layoutMessages.logoutTitle)
    ]

    const profileInfo: ProfileModel = profileMap[profileMap[getLoggedInUserId()]];
    const profileImg = profileInfo?.getProfileImageUrl();

    return (
      <div className="header_menu_container">
        {this.renderMenu()}
        <div className="chat_container">
          <Dropdown overlay={this.userOptions(USER_OPTIONS)} trigger={["click"]}>
            <div className="ml-3 profile_container" onClick={this.avoidDefault}>
              <WFProfilePic
                name={profileInfo?.getName()}
                imageUrl={profileImg}
                retry={{ count: 0 }}
                className="pro_avatar__avatar"
                placeholder={profileInfo?.getName()}
                initialsClassName={"empty_pic"}
                size={100}
                shape="square"
              />
            </div>
          </Dropdown>
        </div>
      </div>
    );
  };

  private logOut = () => {
    Navigator.push(NavigationUrl.generate(URLs.logout));
  };

  private openUserProfile = () => {
    const url = NavigationUrl.generate(URLs.profile, {
      userId: getLoggedInUserId(),
    });
    Navigator.push(url);
  };

  private menuItem = (key, value) => {
    const { t } = this.props;

    let icon = null;
    if (value === translate(t, layoutMessages.myProfileTitle)) {
      icon = <UserOutlined className="header-drop-down-icons" />;
    } else if (value === translate(t, layoutMessages.logoutTitle)) {
      icon = <LogoutOutlined className="header-drop-down-icons" />;
    } else if (value === translate(t, layoutMessages.help)) {
      icon = <QuestionCircleOutlined className="header-drop-down-icons" />;
    } else if (value === translate(t, layoutMessages.settingsTitle)) {
      icon = <SettingOutlined />;
    }

    return (
      <WFMenuItem key={value}>
        <div className="app__layout_menu_item">
          {icon}
          <Text
            textType={TextType.subHeading1}
            fontFamilyVariant={FontFamilyVariant.semibold}
            text={value}
            className="menu_dropdown_style"
          />
        </div>
      </WFMenuItem>
    );
  };

  private userOptions = (USER_OPTIONS: string[]) => {
    const userOptions = USER_OPTIONS.map((item: string, index: number) =>
      this.menuItem(index, item)
    );
    return <Menu onClick={this.handleUserOptionSelection}>{userOptions}</Menu>;
  };

  private avoidDefault = (e) => e.preventDefault();

  private handleUserOptionSelection = ({ key }) => {
    const { t } = this.props;

    if (key === translate(t, layoutMessages.myProfileTitle)) {
      this.openUserProfile();
    } else if (key === translate(t, layoutMessages.logoutTitle)) {
      this.logOut();
    } else {
      Logger.info(
        "Dashboard - handleUserOptionSelection() Option not handled - " + key
      );
    }
  };
}

export const mapStateToProps = (state: any) => {
  return {
    isAuthenticated: isAuthenticatedSelector(state),
    location: state.router.location,
    isWebView: isWebView(state),
    isBetaUser: isBetaUser(state),
    profileMap: getProfileMap(state),
  };
};

export const mapDispatchToProps = (dispatch: any) => {
  return {
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation()
)(withRouter(Dashboard));

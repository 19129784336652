import { platform } from "@constants/config";

export const homepageCarousalArrowIcon = require('./Images/homepageCarousalArrow.svg');
// export const DeleteIcon = require("./Icons/deleteIcon.svg");
export const ChatIcon = require('./Icons/chat.svg');
export const NewChat = require('./Icons/NewChat.svg');
export const PlaceholderImage = require("./Images/PlaceholderGrey.jpg");
export const SendMessage = require('./Icons/SendMessage.svg');
export const ModalClose = require("./Icons/ModalClose.svg");
//@ts-ignore
export const SignIn = platform.WITP ? require('./Images/witpLogin.png') : require('./Images/SignIn.png');
export const ShowPassword = require('./Icons/ShowPassword.svg');
export const HidePassword = require('./Icons/HidePassword.svg');
export const BackBigger = require('./Icons/BackBigger.svg');
export const InactiveLock = require("./Icons/InactiveLock.svg");
export const ActiveLock = require("./Icons/ActiveLock.svg");
export const Password = require("./Images/Password.png");
export const Enroll = require("./Images/Enroll.png");
//@ts-ignore
export const HoldingTablet = platform.WITP ? require("./Images/holdingTabletWitp.png") : require("./Images/holdingTablet.png");
export const GroupPic = require("./Images/Vector.png");
export const WelcomePic = require("./Images/WelcomeImage.png");
export const LoadingGif = require('./Images/loading.gif');
export const EmptyResult = require('./Images/EmptySearchResult.png');
export const EmptyResultGray = require('./Images/EmptySearchGray.png');
export const OpenLibrary = require('./Images/open-lib.png');
export const Cookies = require('./Images/cookies.png');
export const InterviewPrep_EN = require('./Images/interview(EN).png');
export const InterviewPrep_ES = require('./Images/interview(ES).png');
export const InterviewPrep_PT = require('./Images/interview(PT).png');
export const ToolkitScreenShot_EN = require('./Images/toolkitSS(EN).png');
export const ToolkitScreenShot_ES = require('./Images/toolkitSS(ES).png');
export const ToolkitScreenShot_PT = require('./Images/toolkitSS(PT).png');
export const FacToolkitScreenShot_EN = require('./Images/facToolkitSS(EN).png');
export const FacToolkitScreenShot_ES = require('./Images/facToolkitSS(ES).png');
export const FacToolkitScreenShot_PT = require('./Images/facToolkitSS(PT).png');
export const DownloadOutlined = require('./Icons/DownloadOutlined.svg');
export const forumTopicIcon = require('./Icons/forumTopic.svg');
export const WhatsappIcon = require("./Icons/WhatsappIcon.svg");
export const courseContentIcon = require("./Icons/course-content.svg");
export const setupAppointments = require('./Images/setUpAppointments.svg');

export const careerBulb = require("./Icons/cbulb.svg");
export const careerDiamond = require("./Icons/cdiamond.svg");
export const careerHeart = require("./Icons/cheart.svg");
export const cExclaim = require("./Icons/cexclaim.svg");
export const chevron = require("./Icons/chevron-left.svg")

export const chevrona = require("./Icons/chevron.svg")
export const heartLike = require("./Icons/HeartLike.svg")
export const heartLiked = require("./Icons/heart.svg")
export const popular = require("./Icons/Popular.svg")
export const checked = require("./Icons/StepsCompleted.svg")
export const roundPlus = require("./Icons/ic_round-plus.svg")
export const roundMinus = require("./Icons/ic_round-minus.svg")
export const roundPlusCollapse = require("./Icons/ic_round-plus-collapse.svg")



//ICONS CA

export const Achievement = require("./Images-wo/Icons-careerAdvisory/Achievement.svg")
export const Aptitudes = require("./Images-wo/Icons-careerAdvisory/Aptitudes.svg")
export const Artistic = require("./Images-wo/Icons-careerAdvisory/Artistic.svg")
export const Compensation = require("./Images-wo/Icons-careerAdvisory/Compensation.svg")
export const Conventional = require("./Images-wo/Icons-careerAdvisory/Conventional.svg")
export const CreativeThinkingInnovation = require("./Images-wo/Icons-careerAdvisory/CreativeThinkingInnovation.svg")
export const Enterprising = require("./Images-wo/Icons-careerAdvisory/Enterprising.svg")
export const EntrepreneurialSkills = require("./Images-wo/Icons-careerAdvisory/EntrepreneurialSkills.svg")
export const Independence = require("./Images-wo/Icons-careerAdvisory/Independence.svg")
export const Interests = require("./Images-wo/Icons-careerAdvisory/Interests.svg")
export const InterpersonalSkills = require("./Images-wo/Icons-careerAdvisory/InterpersonalSkills.svg")
export const Investigative = require("./Images-wo/Icons-careerAdvisory/Investigative.svg")
export const LogicalReasoningAnalyticalSkills = require("./Images-wo/Icons-careerAdvisory/LogicalReasoningAnalyticalSkills.svg")
export const NumericalAptitudes = require("./Images-wo/Icons-careerAdvisory/NumericalAptitudes.svg")
export const OrganisationalSkill = require("./Images-wo/Icons-careerAdvisory/OrganisationalSkill.svg")
export const PhysicalAndManualSkills = require("./Images-wo/Icons-careerAdvisory/PhysicalAndManualSkills.svg")
export const Realistic = require("./Images-wo/Icons-careerAdvisory/Realistic.svg")
export const Recognition = require("./Images-wo/Icons-careerAdvisory/Recognition.svg")
export const Security = require("./Images-wo/Icons-careerAdvisory/Security.svg")
export const Social = require("./Images-wo/Icons-careerAdvisory/Social.svg")
export const SpatialAwareness = require("./Images-wo/Icons-careerAdvisory/SpatialAwareness.svg")
export const SupportiveEnvironment = require("./Images-wo/Icons-careerAdvisory/SupportiveEnvironment.svg")
export const TechnicalProficiency = require("./Images-wo/Icons-careerAdvisory/TechnicalProficiency.svg")
export const VerbalAbilityAndCommunication = require("./Images-wo/Icons-careerAdvisory/VerbalAbilityAndCommunication​.svg")
export const Values = require("./Images-wo/Icons-careerAdvisory/Values.svg")
export const WorkLifeBalance = require("./Images-wo/Icons-careerAdvisory/Work-LifeBalance.svg")
export const GenericIcon = require("./Images-wo/Icons-careerAdvisory/genericIcon.svg")
export const MatchedRolesMenuIcon = require("./Icons/MatchedRolesMenuIcon.svg")
export const BrowseRoleMenuIcon = require("./Icons/BrowseRoleMenuIcon.svg")
export const FavoriteMenuIcon = require("./Icons/FavoriteMenuIcon.svg")
export const ShareReportMenuIcon = require("./Icons/ShareReportMenuIcon.svg")
export const LockedIcon = require("./Icons/locked.svg")
export const PassionIcon = require("./Icons/PassionIcon.svg")
export const QuestionnaireIcon = require("./Icons/QuestionnaireIcon.svg")

export const careerAdvisorMessages = {
    matchedRoles: {
        key: "careerAdvisor.labelsAndTitles.MATCHED_ROLES",
    },
    browseRoles: {
        key: "careerAdvisor.labelsAndTitles.BROWSE_ROLES",
    },
    favourites: {
        key: "careerAdvisor.labelsAndTitles.FAVOURITES",
    },
    popularRole: {
        key: "careerAdvisor.labelsAndTitles.POPULAR_ROLE"
    },
    about: {
        key: "careerAdvisor.labelsAndTitles.ABOUT",
    },
    careerPaths: {
        key: "careerAdvisor.labelsAndTitles.CAREER_PATHS",
    },
    personalFactors: {
        key: "careerAdvisor.labelsAndTitles.PERSONAL_FACTORS",
    },
    employers: {
        key: "careerAdvisor.labelsAndTitles.EMPLOYERS"
    },
    dayInTheLife: {
        key: "careerAdvisor.labelsAndTitles.DAY_IN_THE_LIFE"
    },
    likeThisJob: {
        key: "careerAdvisor.labelsAndTitles.LIKE_THIS_JOB"
    },
    disLikeThisJob: {
        key: "careerAdvisor.labelsAndTitles.DISLIKE_THIS_JOB"
    },
    prepareForRole: {
        key: "careerAdvisor.labelsAndTitles.PREPARE_FOR_ROLE"
    },
    salaryAndAvialability: {
        key: "careerAdvisor.labelsAndTitles.SALARY_AND_AVIALABILITY"
    },
    avialability: {
        key: "careerAdvisor.labelsAndTitles.AVIALABILITY"
    },
    estimatedRange: {
        key: "careerAdvisor.labelsAndTitles.ESTIMATED_RANGE"
    },
    perMonth: {
        key: "careerAdvisor.labelsAndTitles.PER_MONTH"
    },
    careerPathHeading: {
        key: "careerAdvisor.labelsAndTitles.CAREER_PATH_HEADING"
    },
    careerPathSubHeading: {
        key: "careerAdvisor.labelsAndTitles.CAREER_PATH_SUB_HEADING"
    },
    pursuePath: {
        key: "careerAdvisor.labelsAndTitles.PURSUE_PATH"
    },
    years: {
        key: "careerAdvisor.labelsAndTitles.YEARS"
    },
    personalFactorHeading: {
        key: "careerAdvisor.labelsAndTitles.PERSONAL_FACTOR_HEADING"
    },
    topAptitude: {
        key: "careerAdvisor.labelsAndTitles.TOP_APTITUDE"
    },
    topInterest: {
        key: "careerAdvisor.labelsAndTitles.TOP_INTEREST"
    },
    topValue: {
        key: "careerAdvisor.labelsAndTitles.TOP_VALUE"
    },
    employersHeading: {
        key: "careerAdvisor.labelsAndTitles.EMPLOYERS_HEADING"
    },
    wellKnownEmployers: {
        key: "careerAdvisor.labelsAndTitles.WELL_KNOWN_EMPLOYERS"
    },
    employersProfiles: {
        key: "careerAdvisor.labelsAndTitles.EMPLOYERS_PROFILE"
    },
    selectSectorSubSector: {
        key: "careerAdvisor.messages.SELECT_SECTOR_SUBSECTOR"
    },
    selectSector: {
        key: "careerAdvisor.labelsAndTitles.SELECT_SECTOR",
    },
    selectSubSector: {
        key: "careerAdvisor.labelsAndTitles.SELECT_SUB_SECTOR",
    },
    popularRoles: {
        key: "careerAdvisor.labelsAndTitles.POPULAR_ROLES"
    },
    updateSelfReview: {
        key: "careerAdvisor.messages.UPDATE_SELF_REVIEW"
    },
    favouriteRoles: {
        key: "careerAdvisor.labelsAndTitles.FAVOURITE_ROLES"
    },
    totalJobs: {
        key: "careerAdvisor.labelsAndTitles.TOTAL_JOBS"
    },
    results: {
        key: "careerAdvisor.labelsAndTitles.RESULTS"
    },
    filter: {
        key: "careerAdvisor.labelsAndTitles.FILTER"
    },
    ratingBannerSymbol: {
        key: "careerAdvisor.labelsAndTitles.RATING_BANNER_SYMBOL"
    },
    ratingBannerDescription: {
        key: "careerAdvisor.labelsAndTitles.RATING_BANNER_DESCRIPTION"
    },
    selfReview: {
        key: "careerAdvisor.labelsAndTitles.SELF_REVIEW"
    },
    selfReviewHeading: {
        key: "careerAdvisor.labelsAndTitles.SELF_REVIEW_HEADING"
    },
    planToGoToCollege: {
        key: "careerAdvisor.labelsAndTitles.PLAN_TO_GO_TO_COLLEGE"
    },
    betterRoleRecommendation: {
        key: "careerAdvisor.labelsAndTitles.BETTER_ROLE_RECOMMENDATION"
    },
    questionnaires: {
        key: "careerAdvisor.labelsAndTitles.QUESTIONNAIRES"
    },
    applyFilters: {
        key: "careerAdvisor.labelsAndTitles.APPLY_FILTERS"
    },
    attributeGroupDescription: {
        key: "careerAdvisor.labelsAndTitles.ATTRIBUTE_GROUP_DESCRIPTION"
    },
    apply: {
        key: "careerAdvisor.labelsAndTitles.APPLY"
    },
    favouriteEmptyStateTitle: {
        key: "careerAdvisor.labelsAndTitles.EMPTY_STATE_TITLE"
    },
    favouriteEmptyStateDescription: {
        key: "careerAdvisor.labelsAndTitles.EMPTY_STATE_DESCRITION"
    },
    instructions: {
        key: "careerAdvisor.labelsAndTitles.INSTRUCTIONS"
    },
    quetionsInformation: {
        key: "careerAdvisor.labelsAndTitles.QUESTION_INFORMATION"
    },
    sliderInformation: {
        key: "careerAdvisor.labelsAndTitles.SLIDER_INFORMATION"
    },
    progress: {
        key: "careerAdvisor.labelsAndTitles.PROGRESS"
    },
    nextAttribute: {
        key: "careerAdvisor.labelsAndTitles.NEXT_ATTRIBUTE"
    },
    retake: {
        key: "careerAdvisor.labelsAndTitles.RETAKE"
    },
    summary: {
        key: "careerAdvisor.labelsAndTitles.SUMMARY"
    },
    topAttributes: {
        key: "careerAdvisor.labelsAndTitles.TOP_ATTRIBUTES"
    },
    otherAttributes: {
        key: "careerAdvisor.labelsAndTitles.OTHER_ATTRIBUTES"
    },
    customizeRatings: {
        key: "careerAdvisor.labelsAndTitles.CUSTOMIZE_RATINGS"
    },
    completeOtherQuestionnaires: {
        key: "careerAdvisor.labelsAndTitles.COMPLETE_OTHER_QUESTIONNAIRES"
    },
    completeOtherQuestionnairesDescription: {
        key: "careerAdvisor.labelsAndTitles.COMPLETE_OTHER_QUESTIONNAIRES_DESCRIPTION"
    },
    discoverRecommendedRoles: {
        key: "careerAdvisor.labelsAndTitles.DISCOVER_RECOMMENDED_ROLES"
    },
    discoverRecommendedRolesDescripton: {
        key: "careerAdvisor.labelsAndTitles.DISCOVER_RECOMMENDED_ROLES_DESCRIPTION"
    },
    goToMatchRoles: {
        key: "careerAdvisor.labelsAndTitles.GO_TO_MATCH_ROLES"
    },
    hide: {
        key: "careerAdvisor.labelsAndTitles.HIDE"
    },
    hiddenDescription: {
        key: "careerAdvisor.labelsAndTitles.HIDDEN_DESCRIPTION"
    },
    viewSummary: {
        key: "careerAdvisor.labelsAndTitles.VIEW_SUMMARY"
    },
    collegePlan: {
        key: "careerAdvisor.labelsAndTitles.COLLEGE_PLAN"
    },
    planForHigherStudies: {
        key: "careerAdvisor.labelsAndTitles.PLAN_FOR_HIGHER_STUDIES"
    },
    noPlanForHigherStudies: {
        key: "careerAdvisor.labelsAndTitles.NO_PLAN_FOR_HIGHER_STUDIES"
    },
    notSureHigherStudies: {
        key: "careerAdvisor.labelsAndTitles.NOT_SURE_HIGHER_STUDIES"
    },
    yes: {
        key: "global.labelsAndTitles.YES"
    },
    no: {
        key: "global.labelsAndTitles.NO"
    },
    notSure: {
        key: "global.labelsAndTitles.NOT_SURE"
    },
    tagsEmptyStateTitle: {
        key: "careerAdvisor.labelsAndTitles.TAGS_EMPTY_STATE_TITLE"
    },
    tagsEmptyStateDescription: {
        key: "careerAdvisor.labelsAndTitles.TAGS_EMPTY_STATE_DESCRITION"
    },
    selectionModalTitle: {
        key: "careerAdvisor.labelsAndTitles.SELECTION_MODAL_TITLE"
    },
    questionnaireHeading: {
        key: "careerAdvisor.labelsAndTitles.QUESTIONNAIRE_HEADING"
    },
    questionnaireDescription: {
        key: "careerAdvisor.labelsAndTitles.QUESTIONNAIRE_DESCRIPTION"
    },
    sliderHeading: {
        key: "careerAdvisor.labelsAndTitles.SLIDER_HEADING"
    },
    sliderDescription: {
        key: "careerAdvisor.labelsAndTitles.SLIDER_DESCRIPTION"
    },
    disclaimerTitle: {
        key: "careerAdvisor.labelsAndTitles.DISCLAIMER_TITLE"
    },
    disclaimerDescription: {
        key: "careerAdvisor.labelsAndTitles.DISCLAIMER_DESCRIPTION"
    },
    ratedHigh: {
        key: "careerAdvisor.labelsAndTitles.RATED_HIGH"
    },
    ratedLow: {
        key: "careerAdvisor.labelsAndTitles.RATED_LOW"
    },
    reAttempt: {
        key: "careerAdvisor.labelsAndTitles.REATTEMPT"
    },
    clearAll: {
        key: "careerAdvisor.labelsAndTitles.CLEAR_ALL"
    },
    restart: {
        key: "careerAdvisor.labelsAndTitles.RESTART"
    },
    pssciveCourses: {
        key: "careerAdvisor.labelsAndTitles.PSSCIVE_COURSES"
    },
    pssciveCoursesPlaceHolder: {
        key: "careerAdvisor.labelsAndTitles.PSSCIVE_COURSES_PLACE_HOLDER"
    },
    relatedJobRoles: {
        key: "careerAdvisor.labelsAndTitles.RELATED_JOB_ROLES"
    },
    noRelatedJobs: {
        key: "careerAdvisor.labelsAndTitles.NO_RELATED_JOBS"
    },
    pssciveCourseEmptyState: {
        key: "careerAdvisor.labelsAndTitles.PSSCIVE_COURSE_EMPTY_STATE"
    },
    lookingForPssciveCourse: {
        key: "careerAdvisor.labelsAndTitles.LOOKING_FOR_PSSCIVE_COURSE"
    },
    shareReport: {
        key: "careerAdvisor.labelsAndTitles.SHARE_REPORT"
    },
    share: {
        key: "careerAdvisor.labelsAndTitles.SHARE"
    },
    shareReportDescription: {
        key: "careerAdvisor.labelsAndTitles.SHARE_REPORT_DESC"
    },
    reportFor: {
        key: "careerAdvisor.labelsAndTitles.REPORT_FOR"
    },
    reportForDesc: {
        key: "careerAdvisor.labelsAndTitles.REPORT_DESC"
    },
    questionnaireSummary: {
        key: "careerAdvisor.labelsAndTitles.QUESTIONNAIRES_SUMMARY"
    },
    questionnairesWithCount: {
        key: "careerAdvisor.labelsAndTitles.QUESTIONNAIRES_COUNT"
    },
    copiedToClipBoard: {
        key: "careerAdvisor.labelsAndTitles.COPIED_TO_CLIPBOARD"
    },
    lockedShareReportTitle: {
        key: "careerAdvisor.labelsAndTitles.LOCKED_SHARE_REPORT"
    },
    lockedShareReportDescription: {
        key: "careerAdvisor.labelsAndTitles.LOCKED_SHARE_REPORT_DESC"
    },
    close: {
        key: "global.actions.CLOSE"
    },

};
